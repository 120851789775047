import React, { FC, ReactNode, useCallback, useState } from 'react';
import { Formik, FormikHelpers, FormikValues, FormikProps } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  Row,
  Column,
  Button,
  Modal,
  ModalBody,
  ModalActions,
  ModalAction,
} from '@nimles/react-web-components';
import { InputField } from '@nimles/react-web-forms';

interface SignInProps {
  username?: string;
  onSubmit: (x: any) => Promise<void>;
  onForgotPassword: (x: any) => Promise<void>;
}

export const SignInForm: FC<SignInProps> = ({
  username,
  onSubmit,
  onForgotPassword,
}) => {
  const { t } = useTranslation();

  const values = {
    username: username || '',
    password: '',
  };

  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      await onSubmit(values);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      console.log('error', error?.response?.data);
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({
        username:
          error?.response?.data?.error_description?.replace(/_/g, ' ') ||
          error?.response?.data?.error ||
          error.message,
      });
    }
  };

  return (
    <div>
      <Formik
        initialValues={values}
        onSubmit={handleSubmit}
        render={({
          values,
          handleSubmit,
          isSubmitting,
          isValid,
          errors,
        }: FormikProps<FormikValues>) => {
          const [open, setOpen] = useState(false);

          const handleForgotPassword = useCallback(async (username) => {
            await onForgotPassword(username);
            setOpen(false);
          }, []);

          return (
            <>
              <form onSubmit={handleSubmit}>
                <fieldset>
                  <InputField
                    name="username"
                    label={t('prop.email')}
                    placeholder={t('prop.email')}
                    required
                  />
                  <InputField
                    type="password"
                    name="password"
                    label={t('prop.password')}
                    placeholder={t('prop.password')}
                    required
                  />
                </fieldset>
                <Row justify="space-between">
                  <Column>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setOpen(true);
                      }}
                    >
                      {t('text.forgotPassword')}?
                    </Button>
                  </Column>
                  <Column>
                    <Button
                      primary
                      raised
                      type="submit"
                      disabled={isSubmitting || !isValid}
                    >
                      {t(isSubmitting ? 'action.signingIn' : 'action.signIn')}
                    </Button>
                  </Column>
                </Row>
              </form>
              <Modal open={open} onClose={() => setOpen(false)}>
                <ModalBody>
                  <InputField
                    name="username"
                    label={t('prop.email')}
                    placeholder={t('prop.email')}
                    required
                  />
                </ModalBody>
                <ModalActions
                  onClick={(e) => {
                    e.preventDefault();
                    handleForgotPassword(values.username);
                  }}
                >
                  <ModalAction primary>{t('action.submit')}</ModalAction>
                </ModalActions>
              </Modal>
            </>
          );
        }}
      />
    </div>
  );
};
