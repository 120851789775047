import React, { FC, useContext } from 'react';
import { Header, ElementList } from '@nimles/react-web-components';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import { SiteMetadata } from '../../types';
import { LayoutModel } from '@nimles/models';
import { useQuery } from '@apollo/react-hooks';
import { layoutQuery } from '../Layout';
import queryString from 'query-string';

interface Props {
  alwaysOpen?: boolean;
  location: Location;
  siteMetadata: SiteMetadata;
  layout: LayoutModel;
}

export const HeaderLayout: FC<Props> = ({
  siteMetadata,
  alwaysOpen,
  location,
  layout,
}) => {
  const { language } = useContext(I18nextContext);
  const { draft }: any = location
    ? queryString.parse(location.search)
    : { draft: false };

  const { data } = useQuery<{ layout: LayoutModel }>(layoutQuery, {
    variables: { tenantId: siteMetadata.tenantId, id: layout?.id },
    skip: !draft,
  });

  if (data?.layout) {
    layout = data.layout || layout;
  }

  return (
    <Header alwaysOpen={alwaysOpen} height="80px">
      {layout ? (
        <ElementList
          elements={layout.elements}
          tenantId={siteMetadata.title}
          location={location}
          root="Header"
          culture={language}
        />
      ) : null}
    </Header>
  );
};
