import * as React from 'react';
import { Container, ElementList } from '@nimles/react-web-components';
import styled from '@emotion/styled';
import { SiteMetadata } from '../../types';
import { LayoutModel } from '@nimles/models';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import queryString from 'query-string';
import { useQuery } from '@apollo/react-hooks';
import { layoutQuery } from '../Layout';

const StyledFooter = styled.div`
  z-index: 1;
  padding-top: 20px;
  background-color: ${({ theme }) => theme.footer.background};
  color: ${({ theme }) => theme.footer.color};
  min-height: 20vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
`;

const Copyright = styled.div`
  font-size: 12px;
  padding: 10px 0;
`;

interface LinkItem {
  to: string;
  name: string;
}

interface Props {
  items?: LinkItem[];
  siteMetadata: SiteMetadata;
  layout: LayoutModel;
  location: Location;
}

export const FooterLayout: React.FC<Props> = ({
  siteMetadata,
  layout,
  location,
}) => {
  const { language } = React.useContext(I18nextContext);

  const { draft }: any = location
    ? queryString.parse(location.search)
    : { draft: false };

  const { data } = useQuery<{ layout: LayoutModel }>(layoutQuery, {
    variables: { tenantId: siteMetadata.tenantId, id: layout?.id },
    skip: !draft,
  });

  if (data?.layout) {
    layout = data.layout || layout;
  }

  return (
    <StyledFooter>
      {layout ? (
        <ElementList
          elements={layout.elements}
          tenantId={siteMetadata.title}
          location={location}
          root="Header"
          culture={language}
        />
      ) : null}
      <Container>
        <Copyright>© 2021 Cloud Technologies</Copyright>
      </Container>
    </StyledFooter>
  );
};
