import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby-plugin-react-i18next';

const StyledBrand = styled(Link)<{ vertical?: boolean }>`
  color: inherit;
  text-decoration: none;
  padding: 10px 0;
  font-family: sans-serif;
  font-size: 26px;
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  align-items: center;
  z-index: 1;
`;

const Logo = styled.img<{ vertical?: boolean }>`
  height: ${({ vertical }) => (vertical ? '60px' : '40px')};
  margin: ${({ vertical }) => (vertical ? '0 0 10px' : '0 10px 0 0')};
`;

interface Props {
  name: string;
  logo: string;
  showLogo?: boolean;
  showName?: boolean;
  vertical?: boolean;
}

export const Brand = ({ name, logo, showLogo, showName, ...props }: Props) => (
  <StyledBrand to="/" {...props}>
    {showLogo && logo ? (
      <Logo src={logo} alt={name} vertical={props.vertical} />
    ) : null}
    {(showName && name) || null}
  </StyledBrand>
);
